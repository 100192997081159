import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchApprovall(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          // .get('http://172.25.1.102:9183/api_dadk/mst_approval_sales/view', { params: queryParams })
          .get(`${useAppConfig().AppUrl}mst_approval_sales/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
