import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApprovallList() {
  // Use toast
  const toast = useToast()

  const refApprovallListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'No', sortable: true },
    { key: 'nik', label: 'NIK', sortable: true },
    { key: 'nama', label: 'Nama', sortable: true },
    { key: 'nama_bu', label: 'Bisnis Unit', sortable: true },
    { key: 'div_desc', label: 'Divisi', sortable: true },
    { key: 'segmen_name', label: 'Segmen', sortable: true },
    { key: 'app_level', label: 'Level', sortable: true },
    { key: 'app_batas_bawah', label: 'Batas Bawah', sortable: true },
    { key: 'app_batas_atas', label: 'Batas Atas', sortable: true},    
    { key: 'app_status', label: 'Status', sortable: true},
    { key: 'Actions', sortable: true },
  ]
  const perPage = ref(10)
  const totalApprovall = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25]
  const searchQuery = ref('')
  const sortBy = ref('nik')
  const isSortDirDesc = ref(true)
  const namaFilter = ref(null) 
  const roleFilter = ref(null)
  const divisiFilter = ref(null)
  const segmenFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refApprovallListTable.value ? refApprovallListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalApprovall.value,
    }
  })

  const refetchData = () => {
    refApprovallListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, namaFilter, roleFilter, divisiFilter, segmenFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchApprovall = (ctx, callback) => {
    store
      .dispatch('app-approvall/fetchApprovall', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        app_map_user_pk: namaFilter.value,
        app_div_bu: roleFilter.value,
        app_div_name: divisiFilter.value,
        app_segmen_code: segmenFilter.value,
        app_level: planFilter.value,
        app_status: statusFilter.value,
      })
      .then(response => {
        // const { approvall, total } = response.data

        callback(response.data.data)
        totalApprovall.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Approvall Sales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveApprovallRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveApprovallRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveApprovallStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchApprovall,
    tableColumns,
    perPage,
    currentPage,
    totalApprovall,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refApprovallListTable,

    resolveApprovallRoleVariant,
    resolveApprovallRoleIcon,
    resolveApprovallStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    divisiFilter,
    segmenFilter,
    planFilter,
    statusFilter,
    namaFilter,
  }
}
