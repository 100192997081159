<template>

  <div v-if="session > 0">

    <approvall-list-add-new
      :is-add-new-approvall-sidebar-active.sync="isAddNewApprovallSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <approvall-list-filters
      :nama-filter.sync="namaFilter"
      :role-filter.sync="roleFilter"
      :divisi-filter.sync="divisiFilter"
      :segmen-filter.sync="segmenFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :nama-options="namaOptions"
      :role-options="roleOptions"
      :divisi-options="divisiOptions"
      :segmen-options="segmenOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> 
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery" 
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

              <b-button
                variant="primary"
                :to="{ name: 'apps-approval-sales-add'}"
              >
                <span class="text-nowrap">Tambah Approval Sales</span>
              </b-button>

              
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refApprovallListTable"
        class="position-relative"
        :items="fetchApprovall"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Group Item -->
        <template #cell(approvall)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveApprovallRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.sitename }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveApprovallRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveApprovallRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveApprovallStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-approval-sales-edit', params: { app_map_user_pk: data.item.app_map_user_pk, app_div_bu: data.item.app_div_bu, app_div_name: data.item.app_div_name, app_segmen_code: data.item.app_segmen_code, app_level: data.item.app_level } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showMsgBoxTwo(data.item.app_map_user_pk,data.item.app_div_bu,data.item.app_div_name,data.item.app_segmen_code,data.item.app_level)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalApprovall"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <!-- modal -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal"
        title="Duplicate"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            label="Bisnis Unit"
            label-for="bu"
          >
            <v-select
              v-model="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              input-id="bu_id"
              placeholder="Pilih Bisnis Unit"
            />
          </b-form-group>
        </form>
      </b-modal>

      <template #code>
        {{ codeMessageBox }}
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
// buat ambil api
import axios from '@axios'
// buat nampil data
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ApprovallListFilters from './ApprovallListFilters.vue'
import useApprovallList from './useApprovallList'
import approvallStoreModule from '../approvallStoreModule'
import ApprovallListAddNew from './ApprovallListAddNew.vue'
import { codeMessageBox, codePrevent } from './code'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    ApprovallListFilters,
    ApprovallListAddNew,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      boxOne: '',
      boxTwo: '',
      codeMessageBox,
      codePrevent,
      name: '',
      nameState: null,
      submittedNames: [],
    }
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })
    const APPROVALL_APP_STORE_MODULE_NAME = 'app-approvall'

    // Register module
    if (!store.hasModule(APPROVALL_APP_STORE_MODULE_NAME)) store.registerModule(APPROVALL_APP_STORE_MODULE_NAME, approvallStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APPROVALL_APP_STORE_MODULE_NAME)) store.unregisterModule(APPROVALL_APP_STORE_MODULE_NAME)
    })

    const userDataInfo = ref([])

    const namaOptions = ref([])
    const roleOptions = ref([])
    const divisiOptions = ref([])
    const segmenOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      const resultEmp = await axios.get(`${useAppConfig().AppUrl}global/listEmployee`)
      const dataUser = resultEmp.data[0].emp
      const dataBU = result.data[0].bu
      const dataDiv = result.data[0].divisi
      const dataSeg = result.data[0].segmen
      const combo = []
      const combo1 = []
      const combo2 = []
      const combo3 = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataUser.length; i < len; i++) {
        combo.push({ label: dataUser[i].id_nama_emp, value: dataUser[i].id })
      }
      for (let i = 0, len = dataBU.length; i < len; i++) {
        combo1.push({ label: dataBU[i].nama_bu, value: dataBU[i].id_bu })
      }
      for (let i = 0, len = dataDiv.length; i < len; i++) {
        combo2.push({ label: dataDiv[i].div_desc, value: dataDiv[i].div_name })
      }
      for (let i = 0, len = dataSeg.length; i < len; i++) {
        combo3.push({ label: dataSeg[i].segmen_name, value: dataSeg[i].segmen_code })
      }
      namaOptions.value = combo
      roleOptions.value = combo1
      divisiOptions.value = combo2
      segmenOptions.value = combo3
    })

    const isAddNewApprovallSidebarActive = ref(false)

    const planOptions = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
    ]

    const statusOptions = [
      { label: 'Aktif', value: 1 },
      { label: 'Tidak Aktif', value: 0 },
    ]

    const {
      fetchApprovall,
      tableColumns,
      perPage,
      currentPage,
      totalApprovall,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refApprovallListTable,
      refetchData,

      // UI
      resolveApprovallRoleVariant,
      resolveApprovallRoleIcon,
      resolveApprovallStatusVariant,

      // Extra Filters
      roleFilter,
      divisiFilter,
      segmenFilter,
      planFilter,
      statusFilter,
      namaFilter,
    } = useApprovallList()

    return {
      session,
      // Sidebar
      isAddNewApprovallSidebarActive,

      fetchApprovall,
      tableColumns,
      perPage,
      currentPage,
      totalApprovall,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refApprovallListTable,
      refetchData,
      namaOptions,

      // Filter
      avatarText,

      // UI
      resolveApprovallRoleVariant,
      resolveApprovallRoleIcon,
      resolveApprovallStatusVariant,

      roleOptions,
      divisiOptions,
      segmenOptions,
      planOptions,
      statusOptions,
      userDataInfo,

      // Extra Filters
      namaFilter,
      roleFilter,
      divisiFilter,
      segmenFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    //  rowClass(item, key) {
    //     if (item.cs_category == 'PP') return 'table-success' 
    //   },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async showMsgBoxTwo(id,id_bu,id_div,id_segmen,id_level) {
      this.boxTwo = id
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Apakah yakin ingin menghapus data ini?', {
          title: 'Konfirmasi',
          size: 'sm',

          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete_approval_sales')
            params.append('app_map_user_pk', id)
            params.append('app_div_bu', id_bu)
            params.append('app_div_name', id_div)
            params.append('app_segmen_code', id_segmen)
            params.append('app_level', id_level)
            const result = await axios.post(`${useAppConfig().AppUrl}mst_approval_sales/delete`, params)
            if (result.data.message === 'Berhasil menghapus data') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'BellIcon',
                  text: 'Gagal menghapus data!',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
